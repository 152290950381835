/**
 * Created by daiwenjuan on 17/2/23.
 */
export const ID = '@report-budget-chart'

export default {
  ID: ID,
  GET_BUDGET_YEAR: `${ID}/GET_BUDGET_YEAR`,
  GET_ANNUAL_BUDGET: `${ID}/GET_ANNUAL_BUDGET`,
  GET_REPORT_INFO: `${ID}/GET_REPORT_INFO`,
  GET_BUDGET_ALERT: `${ID}/GET_BUDGET_ALERT`,
  GET_PHONE_CAPTCHA: `${ID}/GET_PHONE_CAPTCHA`,
  SAVE_PHONE: `${ID}/SAVE_PHONE`,
  IS_BIND_PHONE: `${ID}/IS_BIND_PHONE`,
  UPDATE_ALERT: `${ID}/UPDATE_ALERT`,
  GET_BACKLOG_INFO_BY_ID: `${ID}/GET_BACKLOG_INFO_BY_ID`,
  GET_BUDGET_DIMS: `${ID}/GET_BUDGET_DIMS`,
  GET_BUDGET_SEARCH_FLOW: `${ID}/GET_BUDGET_SEARCH_FLOW`,
  GET_REPORT_FLOW_INFO: `${ID}/GET_REPORT_FLOW_INFO`,
  SAVE_ROOT_NODE_ID: `${ID}/SAVE_ROOT_NODE_ID`,
  FIND_EXPORT_WAY: `${ID}/FIND_EXPORT_WAY`,
  ASYNC_EXPORT_BUDGET_TABLE: `${ID}/ASYNC_EXPORT_BUDGET_TABLE`,
  SAVE_BUDGET_DETAIL_TIP: `${ID}/SAVE_BUDGET_DETAIL_TIP`
}
