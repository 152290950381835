// @i18n-ignore-all

import { listPropertyReports } from './report.action'
import { app as api } from '@ekuaibao/whispered'
import loadable from '@loadable/component'

export default [
  {
    id: '@report',
    path: '/reports/budget',
    ref: '/',
    onload: () => import('./menu.view'),
    reducer: () => import('./report.reducer'),

    'list:properties'() {
      return api.dispatch(listPropertyReports(), true)
    }
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'reports-budget',
        pId: 'reports-new',
        powers: ['Budget'],
        weight: 9,
        label: '预算报表',
        href: '/reports/budget'
      }
    ]
  },
  {
    resource: '@report',
    value: {
      BudgetAlertView: loadable(() => import('./budget-chart/budget-alert-view'))
    }
  }
].concat(require('./budget-chart').default)
