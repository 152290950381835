/**
 * Created by daiwenjuan on 17/2/23.
 */
import { getReportInfo, saveRootNodeId, findExportWay, asyncExportBudget, saveBudgetDetailTip } from './action'
import { app as api } from '@ekuaibao/whispered'

export default [
  {
    id: '@report-budget-chart',
    path: '/report/budget-chart/:periodTime/:budgetId/:nodeId/:isRollCalc/:fromNodeId',
    ref: '/',
    onload: () => import('./chart-keel-wrap'),
    reducer: () => import('./reducer'),

    'get:budget'(params) {
      return api.dispatch(getReportInfo(params))
    },

    'save:root:nodeId'(rootNodeId) {
      return api.dispatch(saveRootNodeId(rootNodeId))
    },

    'save:budget:detail:tip'(str) {
      return api.dispatch(saveBudgetDetailTip(str))
    },

    'find:export:way'(params) {
      return api.dispatch(findExportWay(params))
    },

    'async:export:budget'(params) {
      return api.dispatch(asyncExportBudget(params))
    }
  },
  {
    point: '@@layers',
    prefix: '@report-budget-chart',
    onload: () => import('./layers')
  }
]
