/**
 * Created by daiwenjuan on 17/2/23.
 */
import { QuerySelect } from 'ekbc-query-builder';
import { Resource } from '@ekuaibao/fetch';
import key from './key';
import { app } from '@ekuaibao/whispered';

const yearFlows = new Resource('/api/v1/budget/report/allyear');
const budgetFlows = new Resource('/api/v1/budget/report/annual');
const budgetInfo = new Resource('/api/v1/budget/report/info');
const alert = new Resource('/api/v1/budget/config');
const budgetStaffBind = new Resource('/api/v1/budget/config/staffbind');
const budgetFlow = new Resource('/api/v1/budget/report');
const notification = new Resource('/api/v1/budget/config/notice');
const budgetUsedManage = new Resource('/api/flow/v2/filter')

export function getBudgetYears() {
  return {
    type: key.GET_BUDGET_YEAR,
    payload: yearFlows.GET(),
  };
}

export function getAnnualBudget(data) {
  const { start, count, name, annual } = data;
  return budgetFlows.POST('/$annual', { name }, { start, count, annual });
}

export function getReportInfo(data) {
  const { budgetId, nodeId, start = 0, count = 10, name = '' } = data;
  const params = {
    budgetId,
    nodeId,
    start,
    count,
  };
  return {
    type: key.GET_REPORT_INFO,
    payload: budgetInfo.POST('/$budgetId/node/$nodeId', { name }, params),
  };
}

export function getAlert(budgetId, nodeId, data) {
  const params = {
    budgetId: budgetId,
    nodeId: nodeId,
  };
  return {
    type: key.GET_BUDGET_ALERT,
    payload: alert.GET('/alert', params, data),
  };
}

export function updateAlert(params) {
  return {
    type: key.UPDATE_ALERT,
    payload: alert.PUT('/alert', params),
  };
}

export function getCaptcha(params) {
  return {
    type: key.GET_PHONE_CAPTCHA,
    payload: budgetStaffBind.POST('/captcha', params),
  };
}

export function savePhone(params) {
  return {
    type: key.SAVE_PHONE,
    payload: budgetStaffBind.PUT('/captcha', params),
  };
}

export function isBindPhone() {
  return {
    type: key.IS_BIND_PHONE,
    payload: budgetStaffBind.GET('/phoneandemail'),
  };
}

export function getBudgetDims(data) {
  let { budgetId, nodeId, periodTime, rootNodeId } = data;
  let budgetData = {
    nodeId: nodeId,
    budgetId: budgetId,
    fromNodeId: rootNodeId,
    periodTime: periodTime,
  };
  let query = new QuerySelect()
    .filterBy('state.in("archived", "paid", "paying", "approving","sending","receiving")')
    .filterBy(`budgetFlow.budgetId =="${budgetId}"`)
    .filterBy(`budgetFlow.nodeId == "${nodeId}"`)
    .filterBy(`budgetFlow.periodTime == ${periodTime}`)
    .select('id,name');

  const p1 = budgetFlow.POST(
    '/flow/search',
    query.dims('form.specificationId').value(),
    budgetData,
  );
  const p2 = budgetFlow.POST(
    '/flow/search',
    query.dims('form.expenseDepartment').value(),
    budgetData,
  );

  return {
    type: key.GET_BUDGET_DIMS,
    payload: Promise.all([p1, p2]).then((data) => {
      return {
        specList: data[0].items,
        deptList: data[1].items,
      };
    }),
  };
}

export function getBudgetSearchFlow(params, budgetData) {
  const { selectEntityBudgetDetails } = app.require('@lib/entity.join');
  let { value, order } = params.orderBy;
  let { budgetId, nodeId, rootNodeId, periodTime } = budgetData;
  let { start, count } = params.limit;
  let query = new QuerySelect()
    .filterBy(params.filterBy)
    .select(`${selectEntityBudgetDetails()}`)
    .limit(start, count);
  if (value === 'form.budgetBillMoneyDefault.standard') {
    query = query
      .orderBy('form.loanMoney.standard', order.toUpperCase())
      .orderBy('form.requisitionMoney.standard', order.toUpperCase())
      .orderBy('form.expenseMoney.standard', order.toUpperCase());
  } else {
    query = query.orderBy(value, order.toUpperCase());
  }
  query = query.value();
  return {
    type: key.GET_BUDGET_SEARCH_FLOW,
    payload: budgetFlow.POST('/flow/search', query, {
      nodeId: nodeId,
      budgetId: budgetId,
      fromNodeId: rootNodeId,
      periodTime: periodTime,
    }),
  };
}

export function getFlowReportInfo(param) {
  const { selectEntity } = app.require('@lib/entity.join');

  let { id } = param;
  let selectQuery = new QuerySelect()
    .filterBy(`id=="${id}"`)
    .select(`${selectEntity()}`)
    .value();

  const { fetchAttachment, fetchInvoice } = app.require('@lib/attachment-fetch');
  const { joinParams } = app.require('@lib/entity.join');

  return {
    type: key.GET_REPORT_FLOW_INFO,
    payload: budgetFlow
      .POST('/detail/flow/$id', selectQuery, { ...param, ...joinParams() })
      .then(fetchAttachment)
      .then(fetchInvoice),
  };
}

export function saveRootNodeId(rootNodeId) {
  return {
    type: key.SAVE_ROOT_NODE_ID,
    rootNodeId,
  };
}

export function findExportWay(params) {
  let query = new QuerySelect().filterBy(params.filter).value();
  params.filter = query.filterBy;
  return {
    type: key.FIND_EXPORT_WAY,
    payload: budgetFlow.GET(`/export/exportWay`, params),
  };
}

export function asyncExportBudget(params) {
  let query = new QuerySelect().filterBy(params.filter).value();
  params.filter = query.filterBy;
  params.xlsx = 'xlsx';
  return {
    type: key.ASYNC_EXPORT_BUDGET_TABLE,
    payload: budgetFlow.GET(`/export/$xlsx/async`, params),
  };
}

export function saveBudgetDetailTip(data) {
  return {
    type: key.SAVE_BUDGET_DETAIL_TIP,
    budgetDetailTip: data,
  };
}

export function getNotificationTable(param) {
  return notification.GET('/relation', param);
}

export function saveNotificationTable(param) {
  return notification.PUT('/relation', param);
}

export function getNotificationRuleList() {
  return notification.GET('/list');
}

export function getNotificationContentField() {
  return notification.GET('/key');
}

export function checkContentField(params) {
  return notification.POST('/key/check', params);
}

export function getAuthorityList(params) {
  return notification.GET('/user/choose', params);
}

export function saveNotificationRule(params) {
  return notification.PUT('', params);
}

export function deleteNotificationRule(id) {
  return notification.DELETE('/del', { id });
}

export function getBudgetUsedScenes(type) {
  return budgetUsedManage.GET('/$type', { type });
}

export function saveBudgetUsedScenes(params) {
  return budgetUsedManage.POST('/$type', { ...params });
}
