/**************************************************
 * Created by nanyuantingfeng on 21/04/2017 14:25.
 **************************************************/
import { Resource } from '@ekuaibao/fetch'

import key from './key'

const property = new Resource('/api/flow/v2/propertySet')
const permission = new Resource('/api/v1/organization/permissions')

export function listPropertyReports() {
  //查全局字段
  return {
    type: key.LIST_PROPERTY_REPORTS,
    payload: property.GET('')
  }
}

//通过权限名称 获取权限下的管理员人员和角色
export function staffsAndRolesByName(param) {
  return { type: key.GET_STAFFS_ROLES_NAMES, ...param, payload: permission.GET('/$name/authorizedInfo', param) }
}